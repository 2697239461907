<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('elearning_tpm.course_module') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                  <b-col v-if="$store.state.Auth.activeRoleId === 1" lg="6" md="6">
                    <!-- <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('globalTrans.organization')"
                      label-for="training_type_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.org_id"
                        :options="organizationtList"
                        id="org_id"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group> -->
                    <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="org_id"
                  >
                  <template v-slot:label>
                    {{ $t('globalTrans.organization')}}
                  </template>
                  <v-select name="org_id"
                    v-model="search.org_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= organizationtList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <!-- <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_type_id"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_type')}}
                      </template>
                      <b-form-select
                        plain
                        v-model="search.training_type_id"
                        :options="trainingTypeList"
                        id="training_type_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group> -->
                    <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="training_type_id"
                  >
                  <template v-slot:label>
                    {{ $t('elearning_config.training_type')}}
                  </template>
                  <v-select name="training_type_id"
                    v-model="search.training_type_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= trainingTypeList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <!-- <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_category_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_category')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_category_id"
                          :options="trainingCategoryList"
                          id="training_category_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                    </b-form-group> -->
                    <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="training_category_id"
                  >
                  <template v-slot:label>
                    {{ $t('elearning_config.training_category')}}
                  </template>
                  <v-select name="training_category_id"
                    v-model="search.training_category_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= trainingCategoryList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <!-- <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_title_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_title')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_title_id"
                          :options="trainingTitleList"
                          id="training_title_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                    </b-form-group> -->
                    <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="training_title_id"
                  >
                  <template v-slot:label>
                    {{ $t('elearning_config.training_title')}}
                  </template>
                  <v-select name="training_title_id"
                    v-model="search.training_title_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= trainingTitleList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                </b-form-group>
                  </b-col>
                  <b-col lg="12" sm="12" class="text-right">
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('elearning_tpm.course_module') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <router-link to="course-module-form" :class="'btn btn_add_new'">
                        <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                      </router-link>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                          <span class="badge badge-info" v-if="data.item.status == 1">{{$t('globalTrans.save')}}</span>
                                          <span class="badge badge-success" v-else>{{$t('globalTrans.finalSave')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View" v-b-modal.modal-4 @click="edit(data.item)">
                                              <i class="fas fa-eye"></i>
                                            </a>
                                            <router-link v-if="data.item.status == 1" title="Edit" :to="`/training-e-learning-service/tpm/course-module-form?training_title_id=${data.item.training_title_id}`" class="btn_table_action table_action_edit"><i class="ri-ball-pen-fill m-0"></i></router-link>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="$t('elearning_tpm.course_module') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-button variant="primary" @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
              <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button>
            <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { courseModuleList, courseModuleToggleStatus, courseModuleFinalSave } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Details from './Details'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
        return {
          search: {
            circular_memo_no: '',
            org_id: '',
            training_type_id: '',
            training_category_id: '',
            training_title_id: '',
            fiscal_year_id: 0
          },
          trainingCategoryList: [],
          trainingTitleList: [],
          trainingTypeList: [],
          myFilter: (option, text, search) => {
                const temp = search.toLowerCase()
                return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                option.text_bn.toLowerCase().indexOf(temp) > -1
            }
        }
    },
    computed: {
      // trainingTypeList: function () {
      //   return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      // },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      organizationtList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
          const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
            { label: this.$t('globalTrans.organization'), class: 'text-center' },
            { label: this.$t('elearning_config.training_type'), class: 'text-center' },
            { label: this.$t('elearning_config.training_category'), class: 'text-center' },
            { label: this.$t('elearning_config.training_title'), class: 'text-center' },
            { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

           if (this.$i18n.locale === 'bn') {
              keys = [
                { key: 'index' },
                { key: 'org_bn' },
                { key: 'training_type_bn' },
                { key: 'training_category_bn' },
                { key: 'training_title_bn' },
                { key: 'action' }
              ]
          } else {
              keys = [
                { key: 'index' },
                { key: 'org_en' },
                { key: 'training_type' },
                { key: 'training_category' },
                { key: 'training_title' },
                { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
        this.search = Object.assign({}, this.search, {
            // org_id: this.$store.state.dataFilters.orgId,
            fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
        })
        if (this.$store.state.Auth.activeRoleId !== 1) {
        this.search.org_id = this.$store.state.Auth.authUser.org_id
        }
        this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
      'search.org_id': function (newValue) {
      this.trainingTypeList = this.getTypeList(newValue)
      },
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    methods: {
      finalSave (item) {
        this.toggleStatusCustom2(trainingElearningServiceBaseUrl, courseModuleFinalSave, item)
      },
      getTypeList (orgId) {
      const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      if (orgId) {
        return type.filter(item => item.org_id === parseInt(orgId))
      }
      return type
    },
      toggleStatusCustom2 (baseUrl, uri, item) {
        window.vm.$swal({
          title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatusCustom(baseUrl, uri, item)
          }
        })
      },
      changeStatusCustom (baseUrl, uri, item) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
          if (response.success) {
            this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      remove (item) {
          this.changeStatus(trainingElearningServiceBaseUrl, courseModuleToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
      },
      loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, courseModuleList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(law => law.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org_en = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org_en = ''
            orgData.org_bn = ''
          }
          return Object.assign({}, item, trainingTitleData, trainingTypeData, orgData, trainingCategoryData)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>
